





















































































































































































import Vue from 'vue'
import FormLegend from '@/components/FormLegend.vue'
import Organization from '@/store/models/Organization'

export default Vue.extend({
  components: {
    FormLegend
  },

  data() {
    return {
      loading: false,
      form: {
        name: '',
        representativeName: '',
        address: '',
        email: '',
        contact: '',
        interventionZone: '',
        nbAgency: 0,
        admin: {
          firstname: '',
          lastname: '',
          username: '',
          email: '',
          phone: ''
        }
      }
    }
  },

  methods: {
    onSubmit(this: any) {
      this.loading = true
      Organization.api()
        .create({
          ...this.form,
          contact: this.form.contact.replace(/\s/g, ''),
          admin: {
            ...this.form.admin,
            phone: this.form.admin.phone.replace(/\s/g, '')
          }
        })
        .then(() => {
          this.loading = false
          this.$notify.success('Le SFD a été ajouté avec succès')
          this.$router.push({ name: 'sfds.index' })
        })
        .catch((error: any) => {
          this.loading = false
          if (error.response && error.response.status === 400) {
            error.response.data.message.forEach((message: any) => {
              if (message.constraints) {
                Object.keys(message.constraints).forEach((rule: string) => {
                  switch (rule) {
                    case 'isUnique':
                      this.$refs.validator.setErrors({
                        [message.property]: 'Déjà utilisé'
                      })
                      break
                  }
                })
              } else if (message.children) {
                message.children.forEach((child: any) => {
                  Object.keys(child.constraints).forEach((rule: string) => {
                    switch (rule) {
                      case 'isUnique':
                        this.$refs.validator.setErrors({
                          [`${message.property}.${child.property}`]: 'Déjà utilisé'
                        })
                        break
                    }
                  })
                })
              }
            })
          }
        })
    }
  }
})
